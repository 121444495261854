/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/PagePage";
import resolvePageBuilder from "@dataResolvers/resolvePageBuilder";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import PageBuilderFragment from "@fragments/PageBuilderFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query ModulesDefaultQuery($uri: [String]) {
    craft {
      entry(section: "modules", uri: $uri) {
        ... on Craft_modules_default_Entry {
          uid
          title
          url
          # hero
          ancestors {
            uid
            title
            uri
          }
          icon {
            ... on Craft_icons_default_Entry {
              code: code0
            }
          }
          iconColor: color
          heroDescriptor: descriptor0
          heroButton: link0 {
            url
            text
          }
          heroImage: image0 {
            ...ImageFragment
          }
          theme
          # pageBuilder
          pageBuilder {
            ...PageBuilderFragment
          }
          # meta
          uid
          title
          url
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          hideCta: boolean0
          ctaHeading: heading1
          ctaDescriptor: descriptor1
          ctaButton: link2 {
            text
            url
          }
          ctaLink: link3 {
            text
            url
          }
          ctaImage: image1 {
            ...ImageFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    // hero
    ancestors,
    icon,
    iconColor,
    heroDescriptor,
    heroButton,
    heroImage,
    theme,
    // pageBuilder
    pageBuilder,
    // meta
    uid,
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
    hideCta,
    ctaHeading,
    ctaDescriptor,
    ctaButton,
    ctaLink,
    ctaImage,
  } = entry;
  return {
    hero: {
      crumbs: [
        ...ancestors,
        { uid: "solutions-index", uri: "/solutions", title: "Solutions" },
        { uid: "modules-index", uri: "/solutions/modules", title: "Modules" },
        { uid, title },
      ],
      heading: title,
      icon,
      iconColor,
      descriptor: heroDescriptor,
      button: heroButton,
      image: resolveImage(heroImage),
      watermark: false,
      noHeight: false,
      theme,
    },
    pageBuilder: resolvePageBuilder(pageBuilder),
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
    },
    hideCta,
    otherCta: {
      heading: ctaHeading,
      descriptor: ctaDescriptor,
      button: ctaButton,
      link: ctaLink,
      image: resolveImage(ctaImage),
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="ModulesDefaultQuery"
    {...props}
  />
);

export default Page;
